import { PaginationBaseType, TopicItemType } from '@pixcap/ui-core/models/store/app.interface';
import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';

export type LibraryItemTag = {
	tagId: string;
	tagName: string;
};

// only put animation type to show animation items

export enum RENDER_TYPE_TAB {
	'3D_SCENES' = '3d-scenes',
	AI_SCENES = 'ai-scenes',
}

export enum SEARCH_TAB {
	PACKS = 'packs',
	ITEMS = 'items',
}

export enum SEARCH_ITEM_TYPE {
	ALL_ASSETS = 'all-assets',
	THREE_D_ICONS = '3d-icons',
	THREE_D_CHARACTERS = '3d-characters',
	MOCKUPS = 'mockups',
}

export enum LIBRARY_TAB {
	ALL = 'all',
	MODELS = 'models',
	ELEMENTS = 'elements',
	PACKS = 'packs',
	TEMPLATES = 'templates',
	MOCKUPS = 'mockups',
	THREE_D_PACKS = '3d-icon-packs',
	THREE_D_ILLUSTRATIONS = '3d-illustrations',
	THREE_D_CHARACTERS = '3d-characters',
}

export enum LIBRARY_EXPLORE_TABS {
	MOCKUPS = 'mockups',
	CHARACTERS = 'characters',
}

export enum SEARCH_ROUTES {
	// Individual items
	ITEM_ALL_ASSETS = 'all-assets',
	ITEM_ELEMENTS = 'elements',
	ITEM_CHARACTERS = '3d-characters',
	ITEM_MOCKUPS = 'mockups',
	// Packs
	PACK_ALL_ASSET = 'all-asset-packs',
	PACK_ICONS = 'packs',
	PACK_CHARACTERS = '3d-character-packs',
	PACK_MOCKUPS = 'mockup-packs',
}

export enum LIBRARY_EXPORT_TYPE {
	IMAGE = 'image',
	VIDEO = 'video',
	GIF = 'gif',
	GLB = 'glb',
	PIXRENDER_IMAGE = 'image',
	PIXRENDER_GIF = 'gif',
	PIXRENDER_VIDEO = 'video',
}

export type PixcapLibraryFile = {
	itemId?: string;
	itemName?: string;
	category?: string;
	thumbnailUrl?: { url: string; colour: string; width: number; height: number; format: string };
	animationUrl?: { url: string };
	animationThumbnail?: string;
	fileId?: string;
	relatedQuery?: string;
	templateId?: string;
	licence?: string;
	creator?: string;
	animationRigType?: string;
	isBookmarked?: boolean;
	isPro?: boolean;
	price?: number;
	itemType?: string;
	resourceType?: string;
	slug?: string;
	topics?: TopicItemType[];
	tags?: LibraryItemTag[];
	description?: string;
	dimension?: string;
	resolution?: string;
	animationDuration: number;
	isAnimated?: boolean;
	pack?: {
		author?: string;
		isPro?: boolean;
		packId: string;
		packName: string;
		slug: string;
	};
};

export type PackUseCaseImage = {
	imageUrl: string;
};

export type PixcapLibraryPack = {
	packId: string;
	slug?: string;
	packName: string;
	downloadCount?: number;
	itemType?: string;
	resourceType?: string;
	isPro?: boolean;
	isBrandKit?: boolean;
	isFeatured?: boolean;
	createdAt?: string;
	updatedAt?: string;
	packSize?: number;
	packItemIds?: string[];
	tags?: string[];
	packItemThumbnailUrls?: { thumbnailUrl: string; animationThumbnailUrl?: string }[];
	thumbnailUrl?: string;
	thumbnails: { thumbnailUrl: string }[];
	animationThumbnails?: { thumbnailUrl: string }[];
	packItems: PixcapLibraryFile[];
	relatedQuery?: string;
	author?: string;
	price?: number;
	itemIdsToIgnore?: string[];
	useCaseImages?: PackUseCaseImage[];
	eventId?: string;
	topics?: TopicItemType[];
};

export type PixcapLibraryFileDetails = PixcapLibraryFile & {
	relatedQuery?: string;
};

export type LibraryState<StateType> = {
	[stateKey: string]: StateType; // stateKey can be itemTypeKey for assets/collections and collectionId for packs
};

export type LibraryLoadingState = {
	[stateKey: string]: boolean; // stateKey can be itemTypeKey for assets/collections and collectionId for packs
};

export type LibraryFilter = {
	category?: string;
	search?: string;
	isPro?: boolean;
	topic?: string;
	tagSearch?: string;
	resourceTypes?: string[];
	itemType?: string[];
	templateSize?: string[];
};

export type PackFilter = LibraryFilter & {
	isBrandKit?: boolean;
	hasAnimated?: boolean;
};

export type TopicFilter = {
	topicId?: string;
	topicKey: string;
	topicName?: string;
};

export enum MockupOrDesign {
	mockup,
	design,
}

export enum LibraryFilePlans {
	PRO = 'pro',
	FREE = 'free',
}

export enum LibraryFileType {
	ANIMATED = 'animated',
	STATIC = 'static',
}

export enum LibraryQueryTypes {
	SHOW_ONLY_PRO = 'plan',
	ITEM_SLUG = 'itemSlug',
	SEARCH_QUERY = 'searchQuery',
	TAG = 'tag',
	TAGS = 'tag_query',
	PREVIEW_ITEM = 'preview',
	VIEW = 'view',
	TYPE = 'type',
	SIZE = 'size',
	PAGE = 'page',
}

export const NAMESPACE = 'pixcapassets';

export interface IAssetsLibraryState {
	// used for explorer page. TODO convert activeResourceType in editor to use the same logic as activeExplorerTab
	// when we have thew new design that supports the new design system as Explorer v2 for Editor
	activeExplorerTab: string;
	activeSearchItemType: SEARCH_ITEM_TYPE;
	activeSearchItemResourceType: LIBRARY_RESOURCE_TYPE.ELEMENTS | LIBRARY_RESOURCE_TYPE.CHARACTERS;

	libraryFilter: { [itemType: string]: LibraryFilter };

	packFilter: PackFilter;
	templateFilter: LibraryFilter;

	librarySearch: string;
	showOnlyAnimatedFiles: boolean;
	filterDesignSize: string;
	showOnlyProFiles: boolean;
	showOnlyProPacks: boolean;
	showPackOrElement: SEARCH_TAB;

	isLoadingSearchSuggestions: boolean;
	searchSuggestions: string[];

	isFetchingLibraryFiles: LibraryLoadingState;
	libraryFilesState: LibraryState<PaginationBaseType<PixcapLibraryFile>>;

	// Pack
	isFetchingPacks: boolean;
	libraryPacksState: PaginationBaseType<PixcapLibraryPack>;

	// Template
	isFetchingTemplates: boolean;
	libraryTemplateState: PaginationBaseType<PixcapLibraryFile>;
	isFetchingTemplateDetails: boolean;
	templateDetailsState: PixcapLibraryFile;
	selectedLibraryTemplateSlug: string;

	// Topics
	isFetchingUsecaseTopics: boolean;
	usecaseTopics: {
		page: number;
		totalPages: number;
		totalItems: number;
		content: TopicItemType[];
	};
	topicFilter: TopicFilter;

	isFetchingPackDetails: boolean;
	packDetailsState: PixcapLibraryPack;

	isFetchingRelatedModels: boolean;
	relatedModels: PixcapLibraryFile[];

	isFetchingRelatedTemplates: boolean;
	relatedTemplates: PixcapLibraryFile[];

	isFetchingRelatedPacks: boolean;
	relatedPacks: PixcapLibraryPack[];

	selectedLibraryItemSlug: string;

	isFetchingItemDetails: boolean;
	libraryItemDetails: PixcapLibraryFileDetails;

	searchPagePackCount: number;
	searchPageItemCount: number;
	shouldHideSearchPageSuggestedPacks: boolean;
}

export enum MutationTypes {
	SET_ACTIVE_EXPLORER_TAB = 'SET_ACTIVE_EXPLORER_TAB',
	SET_ACTIVE_SEARCH_ITEM_TYPE = 'SET_ACTIVE_SEARCH_ITEM_TYPE',
	SET_ACTIVE_SEARCH_ITEM_RESOURCE_TYPE = 'SET_ACTIVE_SEARCH_ITEM_RESOURCE_TYPE',

	UPDATE_LIBRARY_FILTER = 'UPDATE_LIBRARY_FILTER',
	UPDATE_PACK_FILTER = 'UPDATE_PACK_FILTER',
	UPDATE_TEMPLATE_FILTER = 'UPDATE_TEMPLATE_FILTER',
	UPDATE_LIBRARY_SEARCH = 'UPDATE_LIBRARY_SEARCH',
	UPDATE_SHOW_ONLY_ANIMATED_FILES = 'UPDATE_SHOW_ONLY_ANIMATED_FILES',
	UPDATE_FILTER_DESIGN_SIZE = 'UPDATE_FILTER_DESIGN_SIZE',
	UPDATE_SHOW_ONLY_PRO_FILES = 'UPDATE_SHOW_ONLY_PRO_FILES',
	UPDATE_SHOW_ONLY_PRO_PACKS = 'UPDATE_SHOW_ONLY_PRO_PACKS',
	UPDATE_SHOW_PACK_OR_ELEMENT = 'UPDATE_SHOW_PACK_OR_ELEMENT',

	SET_IS_FETCHING_PRESIGNED_FILE = 'SET_IS_FETCHING_PRESIGNED_FILE',
	SET_IS_FETCHING_SEARCH_SUGGESTIONS = 'SET_IS_FETCHING_SEARCH_SUGGESTIONS',
	UPDATE_SEARCH_SUGGESTIONS = 'UPDATE_SEARCH_SUGGESTIONS',

	SET_IS_FETCHING_LIBRARY_FILES = 'SET_IS_FETCHING_LIBRARY_FILES',
	UPDATE_LIBRARY_FILES_STATE = 'UPDATE_LIBRARY_FILES_STATE',
	RESET_LIBRARY_FILES_STATE = 'RESET_LIBRARY_FILES_STATE',

	SET_IS_FETCHING_LIBRARY_PACKS = 'SET_IS_FETCHING_LIBRARY_PACKS',
	UPDATE_LIBRARY_PACKS_STATE = 'UPDATE_LIBRARY_PACKS_STATE',
	RESET_LIBRARY_PACKS_STATE = 'RESET_LIBRARY_PACKS_STATE',

	// Templates
	SET_IS_FETCHING_LIBRARY_TEMPLATES = 'SET_IS_FETCHING_LIBRARY_TEMPLATES',
	UPDATE_LIBRARY_TEMPLATES_STATE = 'UPDATE_LIBRARY_TEMPLATES_STATE',
	RESET_LIBRARY_TEMPLATES_STATE = 'RESET_LIBRARY_TEMPLATES_STATE',

	SET_IS_FETCHING_TEMPLATE_DETAILS = 'SET_IS_FETCHING_TEMPLATE_DETAILS',
	UPDATE_TEMPLATE_DETAILS_STATE = 'UPDATE_TEMPLATE_DETAILS_STATE',
	SET_SELECTED_LIBRARY_TEMPLATE_SLUG = 'SET_SELECTED_LIBRARY_TEMPLATE_SLUG',

	// Topics
	SET_IS_FETCHING_USECASE_TOPICS = 'SET_IS_FETCHING_USECASE_TOPICS',
	UPDATE_USECASE_TOPICS = 'UPDATE_USECASE_TOPICS',
	UPDATE_USECASE_TOPICS_FILTER = 'UPDATE_USECASE_TOPICS_FILTER',

	// Scenes
	SET_IS_FETCHING_LIBRARY_SCENES = 'SET_IS_FETCHING_LIBRARY_SCENES',
	UPDATE_LIBRARY_SCENES_STATE = 'UPDATE_LIBRARY_SCENES_STATE',
	RESET_LIBRARY_SCENES_STATE = 'RESET_LIBRARY_SCENES_STATE',

	SET_IS_FETCHING_PACK_DETAILS = 'SET_IS_FETCHING_PACK_DETAILS',
	UPDATE_PACK_DETAILS_STATE = 'UPDATE_PACK_DETAILS_STATE',

	SET_IS_FETCHING_RELATED_MODELS = 'SET_IS_FETCHING_RELATED_MODELS',
	UPDATE_RELATED_MODELS = 'UPDATE_RELATED_MODELS',

	SET_IS_FETCHING_RELATED_TEMPLATES = 'SET_IS_FETCHING_RELATED_TEMPLATES',
	UPDATE_RELATED_TEMPLATES = 'UPDATE_RELATED_TEMPLATES',

	SET_IS_FETCHING_RELATED_PACKS = 'SET_IS_FETCHING_RELATED_PACKS',
	UPDATE_RELATED_PACKS = 'UPDATE_RELATED_PACKS',

	SET_SELECTED_LIBRARY_ITEM_SLUG = 'SET_SELECTED_LIBRARY_ITEM_SLUG',

	SET_IS_FETCHING_ITEM_DETAILS = 'SET_IS_FETCHING_ITEM_DETAILS',
	UPDATE_LIBRARY_ITEM_DETAILS = 'UPDATE_LIBRARY_ITEM_DETAILS',

	UPDATE_SEARCH_PAGE_PACK_COUNT = 'UPDATE_SEARCH_PAGE_PACK_COUNT',
	RESET_SEARCH_PAGE_PACK_COUNT = 'RESET_SEARCH_PAGE_PACK_COUNT',

	UPDATE_SEARCH_PAGE_ITEM_COUNT = 'UPDATE_SEARCH_PAGE_ITEM_COUNT',
	RESET_SEARCH_PAGE_ITEM_COUNT = 'RESET_SEARCH_PAGE_ITEM_COUNT',

	SET_SHOULD_HIDE_SEARCH_PAGE_SUGGESTED_PACKS = 'SET_SHOULD_HIDE_SEARCH_PAGE_SUGGESTED_PACKS',
}

export enum GetterTypes {
	GET_LIBRARY_FILES_STATE = 'getLibraryFilesState',
	GET_LIBRARY_ITEM_COUNT_BASED_ON_ACTIVE_TAB = 'getLibraryItemCountBasedOnActiveTab',
	GET_ALL_LIBRARY_ITEM_COUNT = 'getAllLibraryItemCount',
}

export type Getters<S = IAssetsLibraryState> = {
	[GetterTypes.GET_LIBRARY_FILES_STATE]: (state: S) => (stateKey: string) => PaginationBaseType<PixcapLibraryFile> & { isLoading: boolean };
	[GetterTypes.GET_LIBRARY_ITEM_COUNT_BASED_ON_ACTIVE_TAB]: (state: S) => number | undefined;
	[GetterTypes.GET_ALL_LIBRARY_ITEM_COUNT]: (state: S) => number | undefined;
};

export enum ActionTypes {
	VIEW_LIBRARY_ITEM = 'VIEW_LIBRARY_ITEM',
	EXPORT_LIBRARY_ITEM = 'EXPORT_LIBRARY_ITEM',
	FETCH_LIBRARY_FILES = 'FETCH_LIBRARY_FILES',
	FETCH_LIBRARY_FILE_DETAILS = 'FETCH_LIBRARY_FILE_DETAILS',
	FETCH_PACK_ITEMS = 'FETCH_PACK_ITEMS',
	FETCH_PACKS_LIST = 'FETCH_PACKS_LIST',
	FETCH_SUGGESTED_GROUPED_PACK_LIST = 'FETCH_SUGGESTED_GROUPED_PACK_LIST',
	FETCH_TEMPLATES_LIST = 'FETCH_TEMPLATES_LIST',
	FETCH_LIBRARY_TEMPLATE_DETAILS = 'FETCH_LIBRARY_TEMPLATE_DETAILS',

	FETCH_LIBRARY_PACK_DETAILS = 'FETCH_LIBRARY_PACK_DETAILS',
	FETCH_LIBRARY_SEARCH_SUGGESTIONS = 'FETCH_LIBRARY_SEARCH_SUGGESTIONS',

	FETCH_RELATED_MODELS = 'FETCH_RELATED_MODELS',
	FETCH_RELATED_TEMPLATES = 'FETCH_RELATED_TEMPLATES',
	FETCH_RELATED_PACKS = 'FETCH_RELATED_PACKS',

	FETCH_PRESIGNED_FILE = 'FETCH_PRESIGNED_FILE',
	SET_LIBRARY_STATE_FROM_ROUTE = 'SET_LIBRARY_STATE_FROM_ROUTE',

	FETCH_USECASE_TOPICS = 'FETCH_USECASE_TOPICS',
	FETCH_TOPICS_BY_SIZE = 'FETCH_TOPICS_BY_SIZE',
	FETCH_TAGS = 'FETCH_TAGS',
	FETCH_USECASE_TOPIC_TAGS = 'FETCH_USECASE_TOPIC_TAGS',
	FETCH_SUGGESTED_KEYWORDS = 'FETCH_SUGGESTED_KEYWORDS',
	FETCH_RECENT_SEARCH_KEYWORDS = 'FETCH_RECENT_SEARCH_KEYWORDS',
	FETCH_RELATED_KEYWORDS = 'FETCH_RELATED_KEYWORDS',

	UPDATE_RECENT_SEARCH_KEYWORDS = 'UPDATE_RECENT_SEARCH_KEYWORDS',
}
